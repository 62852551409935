

const pressdata = [
    {
        image: "https://bestmediainfo.com/uploads/2018/12/THE-HINDU-Logo_4.jpg",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },

    {
        image: "https://seeklogo.com/images/B/bbc-world-news-logo-10255C2E3B-seeklogo.com.png",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },
    {
        image: "https://upload.wikimedia.org/wikipedia/commons/4/40/New_York_Times_logo_variation.jpg",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },
    {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/The_Economist_Logo.svg/1024px-The_Economist_Logo.svg.png",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },
    {
        image: "https://bestmediainfo.com/uploads/2018/12/THE-HINDU-Logo_4.jpg",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },
    {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/The_Economist_Logo.svg/1024px-The_Economist_Logo.svg.png",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },
    {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/The_Economist_Logo.svg/1024px-The_Economist_Logo.svg.png",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },
    {
        image: "https://bestmediainfo.com/uploads/2018/12/THE-HINDU-Logo_4.jpg",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    },
    {
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/The_Economist_Logo.svg/1024px-The_Economist_Logo.svg.png",
        desc: '   Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae, temporibus.',
        link: 'https://www.umm.digital/'
    }
]

export default pressdata;